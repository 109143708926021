import React from 'react';
import { IHomeState } from "../../home/redux/reducers";
import { getHome } from "../../home/redux/selectors";
import { HomeActionCreators } from "../../home/redux/actions";
import { connect } from "react-redux";
import BlackWindow from "../blackWindow";
import "./ContentPopup.scss"
import { SubscribeActionCreators } from "../../../layouts/main/footer/redux/actions";
import closeIcon from "./img/close.svg";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { DISCOUNT_CODE, LANGUAGE_PREFIX, STORAGE_BASKET_INDEX, VALIDATE_DISCCOUNT } from "../../../utils/constants/variables";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import i18next from "i18next";
import { getStatus } from "../../basket/redux/selectors";
import { isAuthenticated } from "../../login/redux/selectors";
import ImgBlob from "../../home/container/components/imgBlob";
import { getBasketData, getRouteByAltUrl1 } from "../../../helpers/helpers";
import back from "../../../assets/img/grey-background.webp";
import { LazyImage } from "react-lazy-images";
import Input from '../input/Input';
import { getBrandDetail } from '../../../layouts/main/header/redux/selectors';
import ResponseMessage from '../../responseMessage/container';
import { getSuccess } from '../../connection/redux/selectors';

const { detect } = require('detect-browser');

interface Props extends IHomeState {
    data: any,
    status?: string,
    promotion?: string,
    authenticated?: any,
    brandDetails?: any
    subscribe?: any
}

interface State {
    close: boolean,
    discount: string,
    showMessage: string,
    isLocalStorage: boolean
    email: string
    visibleMessage?: boolean
}

class PopUp extends React.Component<Props & typeof SubscribeActionCreators> {
    state: State = {
        close: true,
        discount: "",
        showMessage: "",
        isLocalStorage: false,
        email: '',
        visibleMessage: false

    };

    componentDidMount() {

        const popData = this.getPopData();

        this.setState({ isLocalStorage: !localStorage.getItem("NoDiscount") && !localStorage.getItem(VALIDATE_DISCCOUNT) })


    }

    componentDidUpdate(prevProps, prevState) {


        if (prevProps.data && this.props.data && this.props.data.length > 2 && prevProps.data.length !== this.props.data.length && this.getItemValue("POP", 1).value) {
            if (!localStorage.getItem(VALIDATE_DISCCOUNT) && !localStorage.getItem("NoDiscount")) {
                setTimeout(this.showModal, 1500);
                this.setState({
                    discount: "",
                    showMessage: " "
                })
            }
        }

        if (this.props.status && !prevState.showMessage) {

            if (this.props.status === "success") {
                this.setState({ showMessage: i18next.t('product.basket.25') });
                setTimeout(this.closeModal, 1500);
            } else if (this.props.status === "failed") {
                this.setState({ showMessage: i18next.t('product.basket.20') });
            } else {
                this.setState({ showMessage: i18next.t('product.basket.20') });
            }
        }
        if (this.props.status && !prevProps.status) {
            if (this.props.status.toUpperCase() === "SUCCESS" && this.props.subscribe.result !== "ERROR") {
                this.setState({ subscribed: true });
            }
            setTimeout(() => {
                this.setState({ visibleMessage: false, email: "", accept: false })
                setTimeout(this.closeModal, 100);
            }, 5000)
        }

    }

    startDiscount = () => {
        this.setState({ showMessage: "" });
        const localBasket = localStorage.getItem(STORAGE_BASKET_INDEX)
        const basket = localBasket ? JSON.parse(localBasket) : {};
        const { price } = getBasketData(basket);
        this.props.validateDiscountStart(this.state.discount, price)
    };

    getPopData = () => {
        return Array.isArray(this.props.data) ? this.props.data.filter(item => item.type === "POP") : []
    };
    getItemValue = (key, widget) => {

        const item = this.props.data.find(element => element.type === key && element.widget === widget);

        if (item) {
            return item
        }
        return "";
    };
    getItemValueByName = (key, widget) => {
        const item = this.props.data.find((element) => {
            const elName = `${element.name || ''}`.toLowerCase();

            return elName === key && element.widget === widget
        });

        if (!item || !item.value) return {};

        return item;
    }
    noThanks = () => {
        localStorage.setItem("NoDiscount", "saved");
        this.closeModal()
    };
    closeModal = () => {
        this.setState({ close: false });
    };

    showModal = () => {
        this.setState({ close: true })
    };
    popSave = () => {
        localStorage.setItem("noPop", "saved");
        this.closeModal()
    };
    destroyLocalStorage = () => {
        localStorage.removeItem("NoDiscount");
        localStorage.removeItem(VALIDATE_DISCCOUNT);
        localStorage.removeItem(DISCOUNT_CODE);
    };
    hendleChangeStateEmail = (e) => {
        this.setState({ email: e.target.value })
    };
    handleSubscribe = (e) => {

        const { email } = this.state;

        const body = {
            email,
            webSiteUrl: this.props.brandDetails.name,
            storeCode: this.props.brandDetails.storeCode
        }

        if (email) {
            this.props.subscribeStart(body);
            this.setState({ error: false })
        } else {
            this.setState({ error: true })
        }
        this.setState({ visibleMessage: true });
        // e.preventDefault();
    };
    render() {

        const browser = detect();
        const { promotion, subscribe, status } = this.props;
        const { close, showMessage, discount, isLocalStorage, visibleMessage } = this.state;
        const popData = this.getPopData();
        const isPopDiscount = !!popData.find(item => item.type === "POP" && item.name === "Pop-Discount");
        let statusWitherrorResult = subscribe.result !== "ERROR" && status === "success" ? "success" : "failed"


        if (!close) {
            return null
        }

        const popItemValue = this.getItemValueByName("pop", 1);
        const popItemValue2 = this.getItemValueByName("pop", 2);

        return (
            <>
                {
                    !localStorage.getItem("noPop") && popItemValue.value ?
                        <>
                            <div className="modal-container">
                                <img src={closeIcon} className="x" onClick={this.closeModal}
                                    alt="close" />

                                <div className="contentpopup">
                                    <div className="modal-left">
                                        {/* <div
                                            dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 1).value }}
                                            className='modal-title'
                                        /> */}
                                        <div className='modal-title'>
                                            <h2 dangerouslySetInnerHTML={{__html: popItemValue2 && popItemValue2.value}}></h2>
                                        </div>

                                        {/* <div
                                            dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 2).value }}
                                            className='modal-description' /> */}
                                        <div className='modal-description'>
                                            <Input id={'inp-disc'} handleChange={this.hendleChangeStateEmail} className='discountpopup-input' placeholder={i18next.t("footer.news.2")} />

                                        </div>
                                        <div className="modal-footer">
                                            {/* <button
                                                className="activez-btn">
                                                {
                                                    this.getItemValue("PHOTO", 5).menuHref ?
                                                        <Link onClick={this.popSave}
                                                            to={`${LANGUAGE_PREFIX}${this.getItemValue("PHOTO", 5).menuHref || "/"}`}>
                                                            <div className="activez"
                                                                dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 3).value }} />
                                                        </Link> : ""
                                                }

                                            </button> */}
                                            <button className='discountpopu-btn' onClick={this.handleSubscribe}>{i18next.t("footer.news.4")}</button>
                                            <a className="no-thanks" onClick={this.popSave}>
                                                {/* <div
                                                    dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 4).value }}></div> */}
                                                {i18next.t('footer.news.8')}
                                            </a>
                                        </div>
                                        <div className='ResponseMessage'>
                                            {visibleMessage && status ?
                                                <ResponseMessage status={statusWitherrorResult} errorText={i18next.t('footer.news.6')}
                                                    successText={`${this.state.email} ${i18next.t('footer.news.5')}`} /> : ""}
                                        </div>
                                        <div className='modal-policy'>
                                            <p>{i18next.t("footer.news.3")}</p>
                                            <Link
                                                to={getRouteByAltUrl1(this.props.menuList, "personal_data")}
                                                target="_blank"
                                            >{i18next.t('footer.news.7')}</Link>
                                        </div>
                                    </div>
                                    <div className="modal-right">
                                        {
                                            browser.name === "ie" ? <ImgBlob
                                                src={`${BASE_API_GATEWAY}${this.getItemValue("PHOTO", 5).value}`}
                                                alt={this.getItemValue("PHOTO", 5).altText || "photo"} />
                                                :
                                                <LazyImage
                                                    src={`${BASE_API_GATEWAY}${popItemValue.value}`}
                                                    alt={this.getItemValue("PHOTO", 5).altText || "photo"}
                                                    placeholder={({ imageProps, ref }) => (
                                                        <img
                                                            ref={ref}
                                                            src={back}
                                                            alt={imageProps.alt}

                                                        />
                                                    )}
                                                    actual={({ imageProps }) => (
                                                        <img {...imageProps} />
                                                    )}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                            <BlackWindow />
                        </>
                        :
                        null
                }

                {
                    isPopDiscount && isLocalStorage && this.getItemValue("POP", 1).value ? <>
                        <div className="modal-container">
                            <img src={closeIcon} className="x" onClick={this.closeModal} alt="close" />

                            <div className="contentpopup">

                                <div className="modal-left">

                                    <div dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 1).value }}
                                        className='modal-title'
                                    />
                                    <div dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 2).value }}
                                        className='modal-description' />

                                    <div className="contentpopup-buttons">
                                        <div className="modal-input">

                                            <input
                                                type="text"
                                                name={"discount"}
                                                value={discount}
                                                placeholder={i18next.t('product.basket.21')}
                                                onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                            {
                                                showMessage && isPopDiscount &&
                                                <span
                                                    className={`${showMessage === i18next.t('product.basket.25') ? "success" : "error-text"}`}>{showMessage}</span>
                                            }

                                        </div>
                                        <div className="modal-input-button">
                                            <button
                                                className="activez-btn hoverButtonStyleGold"
                                                onClick={this.startDiscount}>
                                                <div className="activez "
                                                    dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 3).value }} />
                                            </button>
                                            <a className="no-thanks" onClick={this.noThanks}>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: this.getItemValue("POP", 4).value }}></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-right">
                                    <ImgBlob
                                        src={`${BASE_API_GATEWAY}${this.getItemValue("PHOTO", 5).value && this.getItemValue("PHOTO", 5).value}`}
                                        alt={this.getItemValue("PHOTO", 5).altText || "photo"} />
                                </div>
                            </div>
                        </div>
                        <BlackWindow />
                    </> : null
                }
            </>

        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        data: getHome(state),
        authenticated: isAuthenticated(state),
        brandDetails: getBrandDetail(state),
        subscribe: getSuccess(state),
    }
};

const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators(HomeActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUp)
